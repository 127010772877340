import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Header from "../components/header"

const IndexPage = ({data}) => {
  const { markdownRemark } = data;
  const { html } = markdownRemark;

  return (
    <Layout>
      <Header />

      <div
        dangerouslySetInnerHTML={{ __html: html }}
      />
    </Layout>
  );
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { path: { eq: "/" } }) {
      html
    }
  }
`

export default IndexPage
