import React from "react"
import { Link } from "gatsby"

import SocialLinks from './socialLinks'

const Header = () => (
  <>
    <h1>
      <Link to='/'>Jalada</Link>
      <span>(David Somers)</span>
    </h1>
    <SocialLinks />
  </>
);

export default Header;
