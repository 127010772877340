import React from 'react';

import { FaLinkedin, FaInstagramSquare, FaGithubSquare, FaMastodon } from "react-icons/fa";

import FaXTwitterSquare from '../svg/fa-square-x-twitter.svg';
import FaThreadsSquare from '../svg/fa-square-threads.svg';

import './socialLinks.css';

const SocialLinks = () => (

  <div id='social-links'>
    <a href="https://github.com/jalada" target="_blank" rel="noreferrer">
      <FaGithubSquare />
    </a>

    <a href="https://linkedin.com/in/jalada" target="_blank" rel="noreferrer">
      <FaLinkedin />
    </a>

    <a href="https://fed.hvn.network/@jalada" target="_blank" rel="noreferrer">
      <FaMastodon />
    </a>

    <a href="https://threads.net/@jalada" target="_blank" rel="noreferrer">
      <FaThreadsSquare />
    </a>

    <a href="https://instagram.com/jalada" target="_blank" rel="noreferrer">
      <FaInstagramSquare />
    </a>

    <a href="https://x.com/jalada" target="_blank" rel="noreferrer">
      <FaXTwitterSquare />
    </a>
  </div>

);

export default SocialLinks;
